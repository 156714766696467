<template>
    <v-card>
        <v-card-text>
            <psi-form-section
                :value="details"
                name="order-details"
                label="Edit Order Details"
                :fields="detailsFields"
                color="accent"
                @input="updateDetails"
                @update:company="updateCompany"
            >
            </psi-form-section>
        </v-card-text>
        <v-card-actions class="mr-2 mt-n4">
            <v-spacer></v-spacer>
            <v-btn color="accent darken-2">
                <v-icon class="mr-1">mdi-clipboard-account</v-icon> Save Details
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "order-details-edit",
    components: {},
    props: {
        property: {
            type: Object,
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
        screen: {
            type: Object,
            required: false,
            default: () => {},
        },
        products: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            details: {
                type: this.$_.get(this.applicant, "type", ""),
                assigned_to_id: this.$_.get(this.screen, "assigned_to.id", ""),
                property_id: this.$_.get(this.property, "id", ""),
                company_id: this.$_.get(this.property, "company.id", 0),
                source: this.$_.get(this.applicant, "application.source", ""),
            },
            detailsFields: [
                {
                    type: "select",
                    name: "type",
                    key: "type",
                    label: "Type",
                    icon: this.$config("icons.type"),
                    "item-value": "type",
                    "item-text": "type",
                    items: [
                        {
                            type: "Applicant",
                        },
                        {
                            type: "Non-Applicant",
                        },
                        {
                            type: "Cosigner",
                        },
                    ],
                    required: true,
                },
                {
                    type: "select",
                    name: "assigned_to",
                    key: "assigned_to_id",
                    label: "Assigned To",
                    icon: this.$config("icons.assigned_to"),
                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
                {
                    type: "autocomplete",
                    name: "company",
                    key: "company_id",
                    label: "Company",
                    icon: this.$config("icons.company"),
                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
                {
                    type: "select",
                    name: "property",
                    key: "property_id",
                    label: "Property",
                    icon: this.$config("icons.property"),
                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
                {
                    type: "select",
                    name: "source",
                    key: "source",
                    label: "Source",
                    icon: this.$config("icons.source"),
                    "item-value": "source",
                    "item-text": "source",
                    items: [
                        {
                            source: "Online",
                        },
                        {
                            source: "Portal",
                        },
                        {
                            source: "Rent Manager",
                        },
                        {
                            source: "Tenant Tech",
                        },
                    ],
                    required: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Companies", ["currentCompany", "companies"]),
        ...mapGetters("Screens", ["screeners"]),
    },
    watch: {
        property: {
            immediate: true,
            handler(property) {
                if (property && Object.keys(property).length > 0) {
                    this.getCompanies();
                    this.getCurrentCompany(
                        this.$_.get(property, "company.id", 0)
                    );
                }
            },
        },
        screen: {
            immediate: true,
            handler(screen) {
                if (screen && Object.keys(screen).length > 0) {
                    this.getScreeners();
                }
            },
        },
        currentCompany: {
            handler(company) {
                this.detailsFields = this.detailsFields.map((field) => {
                    if (field.name === "property") {
                        field.items = company.properties;
                    }
                    return field;
                });
            },
        },
        screeners: {
            handler(screeners) {
                this.detailsFields = this.detailsFields.map((field) => {
                    if (field.name === "assigned_to") {
                        field.items = screeners;
                    }
                    return field;
                });
            },
        },
        companies: {
            handler(companies) {
                this.detailsFields = this.detailsFields.map((field) => {
                    if (field.name === "company") {
                        field.items = companies;
                    }
                    return field;
                });
            },
        },
    },
    methods: {
        ...mapActions("Companies", ["getCurrentCompany", "getCompanies"]),
        ...mapActions("Screens", ["getScreeners"]),
        updateDetails(data) {
            console.log(data);
        },
        updateCompany(companyId) {
            this.getCurrentCompany(companyId);
        },
    },
};
</script>

<style scoped>
</style>