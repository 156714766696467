var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("psi-form-section", {
            attrs: {
              value: _vm.details,
              name: "order-details",
              label: "Edit Order Details",
              fields: _vm.detailsFields,
              color: "accent"
            },
            on: {
              input: _vm.updateDetails,
              "update:company": _vm.updateCompany
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2 mt-n4" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "accent darken-2" } },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v("mdi-clipboard-account")
              ]),
              _vm._v(" Save Details ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }